@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {
    .appointment-card {
        height: 100%;
        position: relative;

        // Add this new style for completed appointments
        &.complete {
            opacity: 0.7;
            background-color: #f5f5f5;

            .appointment-card-content {
                color: #666;
            }
        }


        .more-actions-button {
            position: absolute;
            top: 20px;
            right: 16px;
            padding: 4px;
            height: 32px;
            width: 32px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.04);
            float: right;

            &:hover {
                background-color: rgba(0, 0, 0, 0.09);
            }

            .anticon {
                font-size: 16px;
                color: #595959;
            }
        }

        .appointment-card-content {
            .tests {
                font-size: $h5;
                font-weight: 600;
                color: #262626;
                margin-bottom: 16px;
                line-height: $h5_line_height;
            }


            .time,
            .location,
            .provider,
            .remote-location {
                color: #595959;
                margin-bottom: 12px;
                font-size: $h6;
                display: flex;
                align-items: flex-start;

                .anticon {
                    margin-right: 8px;
                    font-size: $h6;
                    margin-top: 3px;
                    height: $h6;
                    width: $h6;
                }

                span {
                    line-height: 1.5;
                }
            }

            .remote-location-link {
                color: #595959;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }


        }

        .appointment-status {
            margin-bottom: 16px;
            font-size: 12px;
            margin-left: 0;
            padding-left: 0;
            font-weight: unset;

            .ant-tag {
                border: none;

            }

            .ant-tag-default {
                border: 1px solid #D9D9D9;
            }
        }

        .pending-message {
            margin-top: 8px;
            padding: 8px;
            background-color: #f8f9fa;
            border-radius: 4px;
            font-size: 0.85em;
            color: #6c757d;
            line-height: 1.4;
            margin-bottom: 8px;
        }

        .check-in-details {
            margin-top: 16px;
            padding: 12px;
            background-color: #f5f5f5;
            border-radius: 4px;

            .qr-code {
                display: block;
                margin: 0 auto;
            }

            .confirmation-code {
                margin-top: 8px;
                text-align: center;
                font-size: 14px;
            }
        }

    }


    // // Responsive adjustments
    // @media (max-width: 576px) {
    //     .appointment-card-content {
    //         .tests {
    //             font-size: 15px;
    //         }

    //         .time,
    //         .location {
    //             font-size: 13px;
    //         }

    //         .ant-row {
    //             flex-direction: column;

    //         }
    //     }
    // }
}




@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );

}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}