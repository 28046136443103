$width: 1100px;
$breakpoint_tablet: 768px;
$breakpoint_desktop: 990px;
$breakpoint_desktop_md: 1280px;
$breakpoint_mobile: 600px;
$max_width: 1100px;
$border_radius: 8px;

$max_flow_width: 600px;

// Desktop
$desktop_horizontal_padding: 50px;
$desktop_vertical_padding: 70px;
$desktop_h1: 50px;
$desktop_h1_line_height: 60px;
$desktop_h2: 40px;
$desktop_h2_line_height: 50px;
$desktop_h3: 24px;
$desktop_h3_line_height: calc($desktop_h3*1.5);
$desktop_h4: 18px;
$desktop_h4_line_height: calc($desktop_h4*1.5);
$desktop_h5: 16px;
$desktop_h5_line_height: calc($desktop_h5*1.5);
$desktop_h6: 14px;
$desktop_h6_line_height: calc($desktop_h6*1.5);

// Mobile
$mobile_horizontal_padding: 20px;
$mobile_vertical_padding: 40px;
$mobile_h1: 40px;
$mobile_h1_line_height: 47px;
$mobile_h2: 32px;
$mobile_h2_line_height: 42px;
$mobile_h3: 24px;
$mobile_h3_line_height: calc($mobile_h3*1.5);
$mobile_h4: 18px;
$mobile_h4_line_height: calc($mobile_h4*1.5);
$mobile_h5: 16px;
$mobile_h5_line_height: calc($mobile_h5*1.5);
$mobile_h6: 14px;
$mobile_h6_line_height: calc($mobile_h6*1.33);