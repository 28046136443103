@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {


    .panel-detail-container {
        .mobile-footer {
            position: fixed;
            left: 0;
            right: 0;
            padding: 12px 16px;
            background-color: white;
            box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
            z-index: 10000;
            bottom: 0;

            .mobile-order-button-container {
                gap: 12px;
                width: 100%;
            }

            .order-button {
                width: 100%;
                padding: 14px;
                font-size: 16px;
                height: 50px;
                color: white;
                border: none;
                border-radius: 4px;
                font-weight: 600;
                cursor: pointer;
                transition: background-color 0.2s;
                box-shadow: 0 2px 4px rgba(12, 163, 127, 0.2);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        // Component
        .panel-detail {
            background-color: #fff;
            padding: 0;
            width: 100%;
            overflow-x: hidden;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);

            .panel-header {
                padding: 32px 24px 16px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .panel-info {
                    flex-grow: 1;

                    .panel-name-container {
                        .panel-name {
                            font-size: calc($h3 * 1.33);
                            font-weight: 600;
                            color: #333;
                            margin: 0;
                            line-height: 1.3;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            gap: 12px;
                        }
                    }

                    .panel-tagline {
                        font-size: $h5;
                        font-weight: 400;
                        color: #666;
                    }
                }
            }

            .panel-section {
                padding: 24px;
                border-top: 1px solid #f0f0f0;
                border-bottom: 1px solid #f0f0f0;

                h2 {
                    font-size: $h3;
                    font-weight: 500;
                    margin-bottom: 16px;
                    display: block;
                    margin-top: 0;
                }

                .section-icon {
                    margin-right: 8px;
                }
            }

            .panel-about {
                color: #333;
                line-height: 1.6;
                font-size: $h5;

                p,
                ul,
                ol {
                    margin-bottom: 16px;
                }

                strong {
                    font-weight: 600;
                }

                li {
                    margin-bottom: 8px;
                }

                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 16px;

                    th {
                        font-weight: 500;
                    }

                    td {
                        padding: 8px;
                        border: 1px solid #ddd;
                    }
                }
            }


            .panel-tests {
                .categories-collapse {
                    background: transparent;

                    .ant-collapse-item {
                        margin-bottom: 16px;
                        border-radius: 8px;
                        overflow: hidden;
                        // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
                        border: 1px solid #e8e8e8;

                        .ant-collapse-header {
                            padding: 16px;
                            background: #f7f7f7;
                            border-top: 1px solid #e8e8e8;
                            border-radius: 8px 8px 0px 0px;



                            .category-header {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 100%;
                                font-size: $h5;

                            }
                        }

                        .ant-collapse-content {
                            border-top: none;

                            .ant-collapse-content-box {
                                padding: 0;
                            }
                        }
                    }
                }

                .biomarkers-container {
                    border-radius: 8px;
                    overflow: hidden;
                    background: white;

                    &.single-list {
                        border: 1px solid #e8e8e8;
                        border-radius: 8px;
                        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
                    }

                    .biomarker-item {
                        padding: 12px 16px;
                        border-bottom: 1px solid #f0f0f0;
                        transition: all 0.2s;

                        &:last-child {
                            border-bottom: none;
                        }

                        &:hover {
                            background-color: #fafafa;
                        }

                        .biomarker-content {
                            display: flex;
                            flex-direction: column;

                            .biomarker-title {
                                display: flex;
                                align-items: center;
                                margin-bottom: 4px;
                                font-size: $h6;
                                font-weight: 500;
                            }

                            .biomarker-tagline {
                                font-size: $h6;
                                line-height: 1.5;
                                color: #999;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            }
                        }
                    }
                }

                .section-title {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;

                    .section-icon {
                        color: #666666;
                        margin-right: 8px;
                    }
                }
            }


        }

        // order button container for desktop view
        .order-button-container {
            position: sticky;
            top: 0px;
            display: block;
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;
        }

        @media (max-width: 767px) {
            .panel-header {
                .header-content {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .panel-info {
                    width: 100%;
                    margin-bottom: 10px;
                }

                .order-button-container {
                    display: none; // Hide desktop button on mobile
                }
            }

            .mobile-footer {
                display: block; // Show mobile footer on small screens
            }
        }
    }

    @media screen and (max-width: 768px) {
        .panel-detail-container {
            .panel-detail {
                .panel-header {
                    padding-bottom: 0px;
                }

                .panel-name-container {
                    .panel-name {
                        font-size: 24px;
                    }
                }

                .panel-section {
                    padding: 16px;
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .panel-detail-container {
            .panel-detail {
                .panel-section {
                    padding: 12px;
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );
}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}